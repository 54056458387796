import * as React from 'react';
const SvgBuilding = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 23a.75.75 0 01-.75.75H4a.75.75 0 010-1.5h16a.75.75 0 01.75.75z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 6.6c0-.746.604-1.35 1.35-1.35h10.8c.746 0 1.35.604 1.35 1.35V23h-1.5V6.75H6.75V23h-1.5V6.6z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 18.9a2.15 2.15 0 012.15-2.15h3.2a2.15 2.15 0 012.15 2.15v4.054h-1.5V18.9a.65.65 0 00-.65-.65h-3.2a.65.65 0 00-.65.65v4.054h-1.5V18.9zM8.75 6V2h-1.5v4h1.5zm6.5-5v5h1.5V1h-1.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.739.87a.75.75 0 01-.609.869l-10.5 1.5a.75.75 0 01-.26-1.478l10.5-1.5a.75.75 0 01.869.609zM11.25 23v-6h1.5v6h-1.5zM9.5 8.132a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zm2.5 0a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zm2.5 0a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zm-5 4.25a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zm2.5 0a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zm2.5 0a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
};
const MemoSvgBuilding = React.memo(SvgBuilding);
export default MemoSvgBuilding;
