var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"system-pages-2dd9e1aabb88bd87a78baafb2e27a278a061e834"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/system-pages";

import env from '@y2/environments';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { initSentry, OWNERS } from '../../sentry.config.base';

initSentry(env.name, 'system-pages', OWNERS.DEADPOOL);
